import React, { useEffect, useState } from "react";
import { Navbar, Nav, Container, Dropdown } from "react-bootstrap";
import cx from "./Header.module.scss";
import { FiMenu } from "react-icons/fi";
import { NavLink, useNavigate } from "react-router-dom";
import { useScrollHandler } from "../../../hooks/use-scroll";
import "bootstrap/dist/css/bootstrap.min.css";
import { Banner1, Logo } from "../../../assets/images";
import axios from "axios";
import { IoNotificationsSharp } from "react-icons/io5";
import { NotificationM } from "../Modals";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
interface headerMain {
  showHeaderClass?: string;
}
export default function Header(props: headerMain) {
  let { showHeaderClass } = props;
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showNotification, setshowNotification] = useState(false);
  const handleshowNotificationClose = () => setshowNotification(false);
  const handleshowNotificationShow = () => setshowNotification(true);
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);
  let localData = localStorage.getItem("userToken")!;

  //--//
  const [loaderStatus, setloaderStatus] = useState<any>(
    <ReactLoading type="cylon" color="#5498fd" />
  );
  const [notification, setNotification] = useState<any[]>([]);
  function NotificationApi() {
    let data = JSON.stringify({
      page: "1",
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_WEBSITE}/notifications`,
      headers: {
        Authorization: localStorage.getItem("userToken")!,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response: any) {
        // console.log(response, "NotificationApi");
        setNotification(response.data.data);
        if (response.status == 401) {
          toast.error("Something went wrong.");
          localStorage.removeItem("userData");
          localStorage.removeItem("userToken");
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        }
        if (response.data.data.length == 0) {
          setloaderStatus("No Ride Notification");
        } else {
          setloaderStatus("");
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  }
  //--//
  return (
    <>
      <header
        className={`${cx.main_header}  ${scroll ? cx.affix : ""
          } ${showHeaderClass}`}
      >
        <Navbar className={`navbar navbar-expand-lg ${cx.ak_menu}`}>
          <div className={`${cx.mobile_topbar}`}></div>
          <Container>
            <NavLink className="navbar-brand" to="/">
              <img src={Logo} />
            </NavLink>

            <button
              className={`navbar-toggler ${cx.mobile_menu}`}
              onClick={handleShow}
            >
              <FiMenu />
            </button>
            <Navbar.Collapse
              id="basic-navbar-nav"
              className={` ${show && cx.slide_effect}`}
            >
              <div className={`${cx.menu_box}`}>
                <div className={`${cx.mobile_logo}`}>
                  <a
                    href="javascript:void(0);"
                    className={`${cx.mobile_close}`}
                    onClick={handleClose}
                  >
                    ×
                  </a>
                </div>
                <Nav className="navbar-nav">
                  <li className="nav-item">
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/booking-ride">Book a Taxi</NavLink>
                  </li>
                  {localData && !localStorage.getItem('otp') ? (
                    <li className="nav-item">
                      <NavLink
                        to="#"
                        onClick={() => {
                          NotificationApi();
                          setTimeout(() => {
                            handleshowNotificationShow();
                          }, 500);
                        }}
                      >
                        <IoNotificationsSharp />
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                  {/* <li className="nav-item">
                    <NavLink to="/login" className="btn">
                      Login
                    </NavLink>
                  </li> */}
                  {localData && !localStorage.getItem('otp') ? (
                    <li className="nav-item">
                      <NavLink to="/user/profile" className="btn">
                        My Profile
                      </NavLink>
                    </li>
                  ) : (
                    <li className="nav-item">
                      <NavLink to="/login" className="btn">
                        Login
                      </NavLink>
                    </li>
                  )}
                  {/* <li className="nav-item">
                    <NavLink to="/user/profile" className="btn">
                      My Profile
                    </NavLink>
                  </li> */}
                </Nav>
              </div>

              <div
                className={`${cx.hide_box} ${cx.mobile_close}`}
                onClick={handleClose}
              ></div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>

      <NotificationM
        showNotification={showNotification}
        handleshowNotificationClose={handleshowNotificationClose}
        loaderStatus={loaderStatus}
        notification={notification}
      />
    </>
  );
}