import React, { Fragment, useEffect, useState } from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Modal, Button, Row, Tab, Nav, Col, Form } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import NeedHelp from "./NeedHelpM";
import { ShowReceiptM } from ".";
import { Rating } from "react-simple-star-rating";
import axios from "axios";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const RatingDetailsM = (props: any) => {
  let { ratingShow, handleratingShowClose, newdata } = props;
  //console.log(newdata, "aksaht");
  const navigate = useNavigate();
  const date = new Date(newdata?.start_on * 1000);
  const newdate = date.toLocaleString("en-US", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
  const [needShow, setneedShow] = useState(false);
  const handleneedShowClose = () => setneedShow(false);
  const handleneedShowShow = () => setneedShow(true);

  const [receiptShow, setreceiptShow] = useState(false);
  const handlereceiptShowClose = () => setreceiptShow(false);
  const handlereceiptShowShow = () => setreceiptShow(true);
  const needhelpShowM = () => {
    handleneedShowShow();
    handleratingShowClose();
  };

  const receiptShowM = () => {
    handlereceiptShowShow();
    handleratingShowClose();
  };
  //-----Show-Receipt-Api----//
  const [showReceipt, setShowReceipt] = useState<any>();
  function ShowReceiptApi(id: any) {
    let data = JSON.stringify({
      rideId: id,
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_WEBSITE}/RideReciept`,
      headers: {
        Authorization: localStorage.getItem("userToken")!,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response: any) {
        setShowReceipt(response.data.data);
        if (response.status == 401) {
          toast.error("Something went wrong.");
          localStorage.removeItem("userData");
          localStorage.removeItem("userToken");
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        }
      })
      .catch(function (error) {});
  }
  //---------//NeedHelpApi/Err Msg---------//---//
  //----validation----//
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  //----Err For Sudject-------//
  const [errtick, setErrtick] = useState("");
  ///--------//
  const [cancelRideMSg, setCancelRideMSg] = useState<any>();
  const [driverRideShow, setDriverRideShow] = useState(false);
  const DriverRidehandleClose = () => setDriverRideShow(false);
  const DriverRidehandleShow = () => setDriverRideShow(true);
  const [subject, setSubject] = useState("");
  function NeedHelpApi(postdata: any) {
    if (subject == "") {
      setErrtick("Please Select Type");
    } else{ 
      setErrtick("");
      
      let data = JSON.stringify({
      ride_id: newdata?.ride_id,
      subject: subject,
      description: postdata.description,
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_RIDES}/need_help`,
      headers: {
        Authorization: localStorage.getItem("userToken")!,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response: any) {
        // console.log(response, "NeedHelpApi");
        if (response.status == 200) {
          handleneedShowClose();
          reset()
          setCancelRideMSg(response.data.reply);
          DriverRidehandleShow();
          setTimeout(() => {
            DriverRidehandleClose();
          }, 3000);
        }
      })
      .catch(function (error) {
        //console.log(error);
      })}
   ;
  }
  //-------------------------//--------------------//
  return (
    <>
      <Modal
        centered
        scrollable
        show={ratingShow}
        className={`${m.modalCts} ${m.paymentTab}`}
      >
        <Modal.Header className="border-0">
          <Modal.Title>Rating Details</Modal.Title>
          <button
            className={`${m.closeIcon}`}
            title="Close"
            onClick={handleratingShowClose}
          >
            <MdClose />
          </button>
        </Modal.Header>
        <Modal.Body
          style={{ padding: "0px", overflowX: "hidden", background: "#2a2a2a" }}
        >
          <Row>
            <Col lg={12}>
              <div className={`${m.ratingDetailsM}`}>
                <div className={`${m.rating_card}`}>
                  <div className={`${m.rating_cardInside}`}>
                    <img src={newdata?.driver_image} />
                    <div>
                      <h5>{newdata?.driver_name}</h5>
                      <p>
                        <Rating
                          initialValue={newdata?.avg_rating}
                          allowHover={false}
                        />
                      </p>
                    </div>
                  </div>
                  <div className={`${m.rating_cardInsideRight}`}>
                    <h5>{newdata?.car_title}</h5>
                    <p>{newdata?.plateno}</p>
                  </div>
                </div>

                <div className={`${m.ratingDetails}`}>
                  <div className={`${m.detailsCard}`}>
                    <h5>Card :</h5>
                    <p>$ {newdata?.fare_charged}</p>
                  </div>
                  <div className={`${m.detailsCard}`}>
                    <h5>Date :</h5>
                    <p>{newdate}</p>
                  </div>
                </div>

                <div
                  className={`${m.ratingDetails}`}
                  style={{ backgroundColor: "#2a2a2a" }}
                >
                  <div className={`${m.detailsCard}`}>
                    <h5>Ride Fare :</h5>
                    <p>$ {newdata?.fare_estimate}</p>
                  </div>
                  <div className={`${m.detailsCard}`}>
                    <h5>GST:</h5>
                    <p>$ {newdata?.gst_value}</p>
                  </div>
                  <div className={`${m.detailsCard}`}>
                    <h5>PST :</h5>
                    <p>$ {newdata?.pst_value}</p>
                  </div>

                  <div className={`${m.detailsCardTotal}`}>
                    <h5>Total Fare :</h5>
                    <p>$ {newdata?.fare_charged}</p>
                  </div>
                </div>
              </div>
            </Col>

            <Col lg={12}>
              <div
                className={`${st.formBox}  ${m.needHelp} text-center pb-4 mt-4`}
              >
                <Button
                  className={`btn ${st.submitBtn} `}
                  style={{ color: "#000", borderRadius: "4px" }}
                  onClick={() => {
                    setTimeout(() => {
                      needhelpShowM();
                    }, 1000);
                  }}
                >
                  Need Help ?
                </Button>
                <Button
                  className={`btn  mx-2 ${st.submitBtn} `}
                  style={{ color: "#000", borderRadius: "4px" }}
                  onClick={() => {
                    ShowReceiptApi(newdata.ride_id);
                    setTimeout(() => {
                      receiptShowM();
                    }, 1000);
                  }}
                >
                  Show Receipt
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        scrollable
        show={needShow}
        className={`${m.modalCts} ${m.paymentTab}`}
      >
        <Modal.Header className="border-0">
          <Modal.Title>Need Help</Modal.Title>
          <button
            className={`${m.closeIcon}`}
            title="Close"
            onClick={handleneedShowClose}
          >
            <MdClose />
          </button>
        </Modal.Header>
        <Modal.Body
          style={{ padding: "0px", overflowX: "hidden", background: "#2a2a2a" }}
        >
          <Form onSubmit={handleSubmit(NeedHelpApi)}>
            <Row>
              <Col lg={12}>
                <div
                  className={`${m.ratingDetailsM} `}
                  style={{ padding: "5px 10px" }}
                >
                  <Form>
                    <Form.Group>
                      <Form.Label>Subject</Form.Label>
                      <Form.Select
                      onChange={(e) => {
                        setSubject(e.target.value);
                        setErrtick("");
                      }}
                       
                      >
                        <option>--- Select Type ---</option>
                        <option value={"I Lost An Item"}>
                          {" "}
                          I Lost An Item
                        </option>
                        <option value={"My Driver was Unprofessional"}>
                          My Driver was Unprofessional
                        </option>
                        <option value={"i had Different Issue"}>
                          i had Different Issue
                        </option>
                      </Form.Select>
                      <p style={{ color: "#fcc014" }}> {errtick}</p>
                    </Form.Group>

                    <Form.Group className="mt-4">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        as={"textarea"}
                        rows={1}
                        {...register("description", {
                          required: "This password is required",
                        })}
                      />
                    </Form.Group>
                    {errors?.description?.type === "required" && (
                      <p style={{ color: "#fcc014" }}>required*</p>
                    )}
                  </Form>
                </div>
              </Col>

              <Col lg={12}>
                <div className={`${st.formBox}    text-center pb-4 mt-4`}>
                  <Button
                    className={`btn ${st.submitBtn}  `}
                    style={{ color: "#000", borderRadius: "4px" }}
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        scrollable
        show={driverRideShow}
        className={`${m.modalCts} ${m.paymentTab}`}
        onHide={DriverRidehandleClose}
      >
        <Modal.Body>
          <div
            style={{ color: "525252", fontSize: "20px", fontFamily: "Poppins" }}
            className="d-flex justify-content-center"
          >
            {cancelRideMSg}
          </div>
        </Modal.Body>
      </Modal>
      {/* <NeedHelp
        needShow={needShow}
        handleneedShowClose={handleneedShowClose}
       //transferId={transferId}
      /> */}
      <ShowReceiptM
        receiptShow={receiptShow}
        handlereceiptShowClose={handlereceiptShowClose}
        showReceipt={showReceipt}
      />
    </>
  );
};

export default RatingDetailsM;
