import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import cx from "./TermsConditions.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Container, Form, Col, Row } from "react-bootstrap";
import { Banner1 } from "../../../assets/images";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Checkbox } from "../../../components/Website/Forms";


const TermsConditions = (props: any) => {
  const [show, setShow] = useState(true);
  const [show2, set2Show] = useState(true);
  const [htmlContent, setHtmlContent] = useState<string>('');

  useEffect(() => {
    const fetchHtmlContent = async () => {
      try {
        const response = await fetch('https://taximo.ca/customer/pages/terms_and_conditions');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const html = await response.json();
        console.log(html.data, "htmll")
        setHtmlContent(html.data);
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    };

    fetchHtmlContent();
  }, []);


  return (
    <>
      <section className={`${cx.bookingSection}`}>
        <div className={`${cx.bannersection}`}>
          <img src={Banner1} />
          <div className={`${cx.bannerContent}`}>
            <h2>Terms & Conditions</h2>
          </div>
        </div>
      </section>

      <section className={`${st.sectionPadding}`}>
        <Container>
          <Row>
            <Col lg={12} className={`m-auto`}>
            <div
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default TermsConditions;



// const PrivacyPolicy: React.FC = () => {
//   const [htmlContent, setHtmlContent] = useState<string>('');

//   useEffect(() => {
//     const fetchHtmlContent = async () => {
//       try {
//         const response = await fetch('https://taximo.ca/customer/pages/privacy_policy');
//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }
//         const html = await response.json();
//         console.log(html.data, "htmll")
//         setHtmlContent(html.data);
//       } catch (error) {
//         console.error('There was a problem with the fetch operation:', error);
//       }
//     };

//     fetchHtmlContent();
//   }, []);

//   return (
//     <div
//       dangerouslySetInnerHTML={{ __html: htmlContent }}
//     />
//   );
// };

// export default PrivacyPolicy;

