import React, { useEffect, useState } from "react";
import cx from "./AddedCard.module.scss";
import { AiFillDelete } from "react-icons/ai";
import { DeletePopup } from "../Modals";
import axios from "axios";
import { Button } from "react-bootstrap";


const AddedCard = (props: any) => {
  const [show, setShow] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState("");
  const [tempSelectedCardId, setTempSelectedCardId] = useState("");
  const [cardlistData, setCardlistData] = useState(props.cardlistData || []);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Set initially selected default card (actual is_default one)
  useEffect(() => {
    setSelectedCardId(
      cardlistData?.find((card): any => card?.is_default)?.card_id
    );
  }, [cardlistData]);

  // Temporary card selection
  function selectTempCard(cardId: string) {
    props.selectTempCard(cardId);
 
    props.handlepaymentClose && props.handlepaymentClose();
  }

  // Update card as default (API call + local update)
  function Defaultcard(cardId: string) {
    setSelectedCardId(cardId);
    
    // API call to set the card as default
    let data = JSON.stringify({ card_id: cardId });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_PAYMENTS}/set_default_card`,
      headers: {
        Authorization: localStorage.getItem("userToken")!,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // Update cardlistData locally after successful API call
     
        props.Getcard && props.Getcard();
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  return (
    <>
      {cardlistData &&
        cardlistData.length > 0 &&
        cardlistData.map((item: any) => {
          return (
            <div className="position-relative" key={item._id}>
              <button
                className={`${cx.addedCard} ${cx.radio} ${
                  item._id === tempSelectedCardId ? cx.active : ""
                }`}
                onClick={() => selectTempCard(item._id)}
              >
                <ul>
                  <h6>{item.name}</h6>
                  <li>Card Number : {item.number}</li>
                  <li>Brand : {item.brand}</li>
                </ul>

                {/* <div className={`${cx.signupPoint}`}>
                  <p>
                    <Button
                      className={`${cx.resendBtnBottom}`}
                      onClick={() => Defaultcard(item._id)}
                    >
                      {item.is_default
                        ? "Default Card"
                        : "Set Default Card"}
                    </Button>
                  </p>
                </div> */}
              </button>
              <div className={`${cx.delet_card}`}>
                <AiFillDelete
                  className={`${cx.delet_icon}`}
                  onClick={() => {
                    handleShow();
                    setSelectedCardId(item._id);
                  }}
                />
              </div>
            </div>
          );
        })}
      {props.loaderStatus && (
        <div className="d-flex justify-content-center">{props.loaderStatus}</div>
      )}
      <DeletePopup show={show} handleClose={handleClose} />
    </>
  );
};

export default AddedCard;
